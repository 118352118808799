<template>
  <v-container class="white lighten-5 min-h-100 px-0" fluid>
    <v-card-title>
      <v-spacer></v-spacer>
      Add Doctor
      <v-spacer></v-spacer>
    </v-card-title>
    <v-col cols="12" sm="8" xs="12" class="mx-auto">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-text-field
          v-model="qrcode"
          :rules="qrcodeRules"
          label="QR Code"
          required
        ></v-text-field>

        <v-text-field
          v-model="deviceSerialNumber"
          :rules="deviceSerialNumberRules"
          label="Device Serial Number"
          required
        ></v-text-field>

        <v-text-field
          v-model="checkCode"
          :rules="checkCodeRules"
          label="Check Code"
          required
          type="number"
        ></v-text-field>

        <v-text-field
          v-model="name"
          :rules="nameRules"
          label="Name"
          required
        ></v-text-field>

        <v-text-field
          v-model="email"
          :rules="emailRules"
          label="E-mail"
          required
          type="email"
        ></v-text-field>

        <v-text-field
          v-model="phoneNo"
          :rules="phoneNoRules"
          label="Phone Number"
          required
        ></v-text-field>

        <v-text-field
          v-model="qualification"
          :rules="qualificationRules"
          label="Qualification"
          required
        ></v-text-field>

        <v-textarea
          v-model="address"
          :rules="addressRules"
          label="Address"
          required
        ></v-textarea>

        <v-btn
          :disabled="!valid"
          color="success"
          class="mr-4"
          @click="validateForm"
        >
          Submit
        </v-btn>

        <!-- <v-btn color="error" class="mr-4" @click="reset"> Reset Form </v-btn> -->
      </v-form>
    </v-col>
  </v-container>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex';

export default {
  name: 'AddDoctor',
  data: () => ({
    valid: true,
    qrcode: '',
    qrcodeRules: [(v) => !!v || 'QR Code is required'],
    deviceSerialNumber: '',
    deviceSerialNumberRules: [(v) => !!v || 'Device Serial Number is required'],
    checkCode: '',
    checkCodeRules: [(v) => !!v || 'Check Code is required'],
    name: '',
    nameRules: [(v) => !!v || 'Name is required'],
    email: '',
    emailRules: [(v) => !!v || 'E-mail is required'],
    phoneNo: '',
    phoneNoRules: [(v) => !!v || 'Phone Number is required'],
    qualification: '',
    qualificationRules: [(v) => !!v || 'Qualification is required'],
    address: '',
    addressRules: [(v) => !!v || 'Address is required'],
  }),
  watch: {
    getRole(r) {
      if (r?.id == null || r?.id != this.$store.superadmin) {
        this.$router.replace('/');
      }
    },
  },
  created() {
    if (this.$route.params.id) {
      const list = this.doctors.filter((e) => e.id == this.$route.params.id);
      if (list.length > 0) {
        this.qrcode = list[0].qr_code;
        this.deviceSerialNumber = list[0].device_serial_number;
        this.checkCode = list[0].check_code;
        this.name = list[0].name;
        this.email = list[0].email;
        this.phoneNo = list[0].phone_no;
        this.qualification = list[0].qualification;
        this.address = list[0].address;
      }
    }
  },
  mounted() {
    if (this.getRole?.id != this.superadmin) {
      this.$router.push('/');
      return;
    }
  },
  computed: {
    ...mapGetters(['getRole']),
    ...mapState({
      doctors: (state) => state.doctors,
      superadmin: (state) => state.superadmin,
    }),
  },
  methods: {
    ...mapActions(['addDoctor']),
    async validateForm() {
      const validate = this.$refs.form.validate();
      if (validate) {
        await this.addDoctor({
          doctor: {
            qr_code: this.qrcode,
            device_serial_number: this.deviceSerialNumber,
            check_code: this.checkCode,
            name: this.name,
            email: this.email,
            phone_no: this.phoneNo,
            qualification: this.qualification,
            address: this.address,
          },
          id: this.$route.params.id,
        });
        this.$router.go(-1);
      }
    },
    reset() {
      this.$refs.form.reset();
    },
  },
};
</script>
